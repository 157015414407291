<template>
  <div class="card-inner">
      <table class="table table-striped" v-if="tenantStats">
            <tr>
            <td>Name</td>
            <td>{{tenantComponent.tenantName}}</td>
            </tr>
            <tr>
            <td>ID</td>
            <td>{{tenantComponent.id}}</td>
            </tr>
            <tr>
            <td>Date Created</td>
            <td>{{ $moment(tenantComponent.createdDate).format("LL") }}</td>
            </tr>
            <tr>
            <td>Number Of Employees</td>
            <td>{{tenantComponent.numberOfEmployees}}</td>
            </tr>
            <tr>
            <td>Number Of Tickets Created</td>
            <td>{{ tenantStats.ticketsCount }}</td>
            </tr>
            <tr>
            <td>Number Of Holidays Logged</td>
            <td>{{ tenantStats.holidayCount }}</td>
            </tr>
            <tr>
            <td>Number Of Files Uploaded</td>
            <td>{{ tenantStats.filesCount }}</td>
            </tr>
        </table>
  </div>
</template>

<script>
export default {
    props: {
        tenantId: {
        type: String
        },
    },
    data() {
        return {
            tenantComponent: null,
            tenantStats: null,
        }
    },
    created() {
        this.getTenant()
        this.getCounts()
    },
    methods: {
        getTenant () {
            this.$http.get('/admin/Get_TenantComponent/' + this.tenantId)
            .then((response) => {
            this.tenantComponent = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getCounts() {
            this.$http.get('/admin/Get_TenantStats/' + this.tenantId)
            .then((response) => {
            this.tenantStats = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
    },
}
</script>

<style>

</style>