<template>
 <div class="nk-block">
    <div class="card card-bordered p-3">
      <div class="card-inner-group">
        <a-tabs>
          <a-tab-pane key="1" tab="Details">
                <div class="row">
                  <div class="col-md-8">
                    <tenantDetails :tenantId="tenantId"/>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-12">
                        <a-button class="btn btn-dark btn-block" @click="addUser()">Add User</a-button>
                      </div>
                    </div>
                    <tenantStats :tenantId="tenantId"/>
                  </div>
                </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="Modules">
            <tenantModules :tenantId="tenantId"/>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <!-- MODALS -->
    <a-modal title="Add User"
      width="60%"
      :closable="true"
      v-model="showAddUser">
      <addUserWizard :tenantId="tenantId"/>
    </a-modal>
  </div>
</template>
<script>
// Import FilePond

import tenantDetails from '@/components/admin/tenants/details.vue'
import tenantStats from '@/components/admin/tenants/stats.vue'
import tenantModules from '@/components/admin/tenants/modules.vue'
import addUserWizard from '@/components/users/addUserWizard.vue'

export default {
  components: { tenantDetails, tenantStats, tenantModules, addUserWizard },
  data() {
    return {
      tenantId: null,
      newUpdate: { name: '', description: '', displayToUser: '' },
      showAddUser: false,
      newUser: {}
    }
  },
  created () {
    this.getView()
    this.tenantId = this.$route.query.tenantId
  },
  methods: {
    getView() {
      this.$http.get('/ViewAccess/tenant')
      .then(() => {
      })
      .catch(() => {
      })
    },
    addUser(){
      this.showAddUser = true
    }
  },
}
</script>
<style>
</style>
