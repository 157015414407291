<template>
  <div class="card-inner">
        <h5><em class="icon ni ni-building"/> Tenant Details</h5>
        <vue-good-table
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="tenantComponent.length"
            :rows="tenantComponent"
            :columns="columns"
            styleClass="vgt-table condensed"
            v-if="tenantComponent"
        >
            <template slot="table-row" slot-scope="props" v-bind:class="{'xxx': true}">
                <div v-if="props.column.field == 'failed'" v-bind:class="{'bg-danger': props.row.failed === true,'bg-success': props.row.failed === false}" class="umbril_td_container text-center text-light"  >
                    <span v-if="props.row.failed">FAILED</span>
                    <span v-if="!props.row.failed">PASSED</span>
                </div>
                <div v-if="props.column.field == 'createdDate'">
                {{ $moment(props.row.createdDate).format("LL") }}
                </div>
                <div class="umbril_td_container text-center" v-else-if="props.column.field == 'actions'" >
                    <button v-on:click="impersonateUser(props.row.id)" class="btn btn-xs btn-success mr-2 p5">Impersonate</button>
                </div>
                <div class="umbril_td_container" v-else>
                    {{props.formattedRow[props.column.field]}}
                </div>
            </template>
        </vue-good-table>
  </div>
</template>

<script>
export default {
    props: {
        tenantId: {
        type: String
        },
    },
    data() {
        return {
            tenantComponent: null,
            columns: [
                { label: 'Email', hidden: false, field: 'email' },
                { label: 'Signed Up', hidden: false, field: 'createdDate' },
                { label: 'Actions', hidden: false, field: 'actions', thClass: 'text-center', width: '200px' },
            ],
        }
    },
    created() {
        this.get_Users()
    },
    methods: {
        get_Users () {
            this.$http.get('/users/get_TenantUsers/' + this.tenantId)
            .then((response) => {
            this.tenantComponent = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        switchToImpersonate (userData) {  
            //store my settings
            localStorage.originTrimToken = localStorage.trimToken 
            
            // set impersonation settings
            localStorage.trimToken = userData.token 
            this.$store.commit('updateUserSettings', userData.settings)
            this.$nextTick(() => { 
              this.$router.push({ name: 'myprofile', query: { forceReload: true } })
            })
        },
        impersonateUser (id) {
            this.$http.get('/admin/Impersonate_User/' + id)
            .then((response) => {
            this.switchToImpersonate(response.data)
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        onPageChange(){

        },
        onSortChange(){

        },
        onColumnFilter(){

        },
        onPerPageChange(){

        }
    }
}
</script>

<style>

</style>