<template>
  <div class="card-inner">
      <div class="row" v-for="m in enabledModules" :key="m.id">
            <div class="col-12 between-center flex-wrap flex-md-nowrap g-3">
                <div class="nk-block-text" >
                <h6>Enable {{ m.moduleName }}</h6>
                <p>This setting will enable the {{ m.moduleName }} module.</p>
                </div>
                <div class="nk-block-actions">
                    <ul class="align-center gx-3">
                        <li class="order-md-last">
                            <a-switch v-model="m.enabled" @change="updateEnabledModules(m)" id="priorities"/>   
                        </li>
                    </ul>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props: {
        tenantId: {
            type: String
        }
    },
    data() {
        return {
            enabledModules: null,
        }
    },
    created() {
        this.getEnabledModules()
    },
    methods: {
        getEnabledModules() {
            this.$http.get('/settings/Get_Tenant_Enabled_Modules/' + this.tenantId)
            .then((response) => {
            this.enabledModules = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        updateEnabledModules(m){
            m.tenantId = this.tenantId
            this.$http.post('/settings/Update_Tenant_Enabled_Modules', m)
            .then(() => {
                this.$message.success('Module Updated')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>